<template>
  <div class="login-box">
    <div class="login-card">
      <div class="title">大赛官网管理系统</div>
      <div class="row">
        <label>账户</label>
        <input type="text" placeholder="请输入您的账户" v-model="username">
      </div>
      <div class="row">
        <label>密码</label>
        <input type="password" placeHolder="请输入账户密码" v-model="password">
      </div>
      <div class="row">
        <div class="button" @click="doLogin">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import {Warning} from "@/plugins/warning";
import {doLogin} from "@/request/api";
import md5 from 'js-md5';
export default {
  name: "login",
  components:{

  },
  props:{

  },
  data() {
    return {
      username:"",
      password:"",
    }
  },
  computed: {},
  created() {

  },
  mounted() {
  },
  methods: {
    doLogin(){
      if(!this.username){
        Warning.open('请输入用户名')
        return false
      }
      if(!this.password){
        Warning.open('请输入密码!')
        return false
      }

      const requestData = {
        username:this.username,
        password: md5(this.password),
      }
      doLogin(requestData).then((res)=>{
        this.$store.commit('infoModule/updateToken',res.data.token);
        this.$store.commit('infoModule/updateUserInfo',res.data.admin);


        Warning.success('登录成功，即将进入系统')
        setTimeout(()=>{
          this.$router.push({name:'home'})
        },2500)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.login-box{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(~@/assets/login_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .login-card {
    background: white;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
    .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 24px;
    }
    .row{
      display: flex;
      flex-flow: row wrap;
      margin-bottom: 20px;
      & > label{
        position: relative;
        display: inline-flex;
        align-items: center;
        height: 32px;
        color: #000;
        font-size: 16px;
        margin-right: 15px;
      }
      & > input{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        position: relative;
        display: inline-block;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
      }
      .button{
        width: 100%;
        font-size: 14px;
        color: #fff;
        border-color: #1890ff;
        background: #1890ff;
        position: relative;
        display: inline-block;
        height: 32px;
        padding: 4px 15px;
        cursor: pointer;
      }
    }
  }
}
</style>
